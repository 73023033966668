import { combineReducers } from 'redux';

import alert from './alert';
import breadcrumb from './breadcrumb';

import productos from './productos';
import cotizar from './cotizar';

export default combineReducers({
    alert, 
    breadcrumb, 
    productos, 
    cotizar
});