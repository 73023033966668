import React from 'react';
import { useSelector } from 'react-redux';

const Breadcrumb = () => {

    const breadcrumb = useSelector(state => state.breadcrumb);

    return (
        (breadcrumb.length > 0) &&
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{breadcrumb[breadcrumb.length - 1].nombre}</h2>
                        <ol>
                            {
                                breadcrumb.map( (elemento, index) => (
                                    <li key={'breadcrumb-' + index}>
                                        {
                                            elemento.activo ? 
                                                elemento.nombre
                                            :
                                                <a href={elemento.ruta}>{elemento.nombre}</a>
                                        }
                                    </li>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </section>
    );
}

export default Breadcrumb;
