import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteCotizacion, recuperarCotizacion, sendCotizacion } from '../../actions/cotizar';
import { setAlert } from '../../actions/alert';

const Cotizar = () => {
    
    const dispatch = useDispatch();

    const cotizacion = useSelector(state => state.cotizar);

    const [cotizacionData, setCotizacionData] = useState({
        productos: [], 
        nombre: null, 
        email: null, 
        telefono: null, 
        comentarios: null
    });

    const [envioCotizacion, setEnvioCotizacion] = useState(false);

    const onChange = (e) => {
        setCotizacionData({
            ...cotizacionData,
            [e.target.name]: e.target.value
        });
    }

    const deleteProducto = (id) => {
        dispatch(deleteCotizacion(id));
    }

    const submitCotizacion = async () => {
        if(cotizacionData.productos.length > 0 && cotizacionData.nombre && (cotizacionData.email || cotizacionData.telefono)){
            await setEnvioCotizacion(true);
            await dispatch(sendCotizacion(cotizacionData));
            await setEnvioCotizacion(false);
            await setCotizacionData({
                productos: [], 
                nombre: null, 
                email: null, 
                telefono: null, 
                comentarios: null
            });
        }else{
            if(cotizacionData.productos.length == 0){
                dispatch(setAlert('Debe haber al menos un producto a cotizar', 'danger'));
            }

            if(!cotizacionData.nombre){
                dispatch(setAlert('Tu nombre es requerido', 'danger'));
            }

            if(!cotizacionData.email || !cotizacionData.telefono){
                dispatch(setAlert('Tu teléfono o correo son requeridos', 'danger'));
            }
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        if (localStorage.cotizacion) {
            let cotizacion = JSON.parse(localStorage.getItem('cotizacion'));
            dispatch(recuperarCotizacion(cotizacion));
        }
    }, []);

    useEffect(() => {
        if(cotizacion.productos.length > 0){
            setCotizacionData({
                ...cotizacion
            });
        }
    }, [cotizacion, cotizacion.productos]);

    return (
    	<Fragment>
            <section id="cotizador" className="services">
                <div className="container">
                    {
                        cotizacion.productos.length > 0 ?
                            <div className="row">
                                <div className="col-md-6 mt-2 d-block">
                                    {
                                        cotizacion.productos.map((producto, index) => {
                                            return(
                                                <div key={'producto-' + index} className="col-md-12 m-1 row contenedor-elemento">
                                                    <div className="col-md-3 contenedor-producto">
                                                        <img className="image-producto" src={producto.imagen} />
                                                    </div>

                                                    <div className="col-md-9 contenedor-descripcion">
                                                        <p>{producto.cantidad + (producto.cantidad == 1 ? ' pz. ' : ' pzs. ') + '- ' + producto.nombre}</p>
                                                        <button type="button" className="boton-producto mx-2 float-end" onClick={() => deleteProducto(producto.producto)}><i className="bx bx-trash"></i></button>
                                                        <a href={'/productos/detalles/' + producto.producto} className="boton-producto mx-2 float-end"><i className="bx bxs-edit-alt"></i></a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="col-md-6 mt-2">
                                    <div className="php-email-form">
                                        <div className="row">
                                            <div className="col-md-12 form-group mt-3">
                                                <input type="text" name="nombre" className="form-control" placeholder="Nombre" value={cotizacionData.nombre || ''} onChange={e => onChange(e)} />
                                            </div>
                                            <div className="col-md-6 form-group mt-3">
                                                <input type="email" className="form-control" name="email" placeholder="Correo" value={cotizacionData.email || ''} onChange={e => onChange(e)} />
                                            </div>
                                            <div className="col-md-6 form-group mt-3">
                                                <input type="text" name="telefono" className="form-control" placeholder="Teléfono" value={cotizacionData.telefono || ''} onChange={e => onChange(e)} />
                                            </div>
                                        </div>

                                        <div className="form-group mt-3">
                                            <textarea className="form-control" name="comentarios" rows="5" placeholder="Comentarios" value={cotizacionData.comentarios || ''} onChange={e => onChange(e)}></textarea>
                                        </div>

                                        {
                                            envioCotizacion ?
                                                <div className="text-center mt-3"><button type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Envíanos tu cotización</button></div>
                                            :
                                                <div className="text-center mt-3"><button type="button" onClick={() => submitCotizacion()}>Envíanos tu solicitud</button></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="icon-box icon-box-orange">
                                        <div className="icon"><i className="bx bx-dollar"></i></div>
                                        <h4 className="title"><a href="">¿Quieres cotizar?</a></h4>
                                        <p className="description">Comienza agregando los productos que necesites cotizar en la sección "Productos".</p>

                                        <a href="/productos" className="btn-get-started animate__animated animate__fadeInUp">Ir a productos</a>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </section>
	    </Fragment>
    )
}

export default Cotizar;