import React, { useEffect, Fragment } from 'react';

const Inicio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section id="hero" className="d-flex justify-cntent-center align-items-center">
                <div id="heroCarousel" className="container carousel carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

                <div className="carousel-item active">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown"><span>IMHABLOCK</span></h2>
                        <p className="animate__animated animate__fadeInUp">Materiales vibrocomprimidos para la construcción.</p>
                        <a href="#nosotros" className="btn-get-started animate__animated animate__fadeInUp">Ver más</a>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown"><span>IMHABLOCK</span></h2>
                        <p className="animate__animated animate__fadeInUp">Amplio catálogo de productos de alta calidad.</p>
                        <a href="/productos" className="btn-get-started animate__animated animate__fadeInUp">Ver más</a>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown"><span>IMHABLOCK</span></h2>
                        <p className="animate__animated animate__fadeInUp">Realiza tu cotización en 2 sencillos pasos.</p>
                        <a href="/cotizar" className="btn-get-started animate__animated animate__fadeInUp">Cotizar</a>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
                </a>

                <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
                </a>

                </div>
            </section>
            
            <section id="nosotros" className="services">
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up">
                            <div className="icon-box icon-box-orange">
                                <div className="icon"><i className="bx bxs-component"></i></div>
                                <h4 className="title"><a href="">Todo esta aquí</a></h4>
                                <p className="description">Encuentra todos los materiales que necesitas para tu proyecto aquí.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                            <div className="icon-box icon-box-orange">
                                <div className="icon"><i className="bx bxs-shield-plus"></i></div>
                                <h4 className="title"><a href="">Calidad garantizada</a></h4>
                                <p className="description">Controlamos cada aspecto de la producción de nuestros materiales y productos.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon-box icon-box-orange">
                                <div className="icon"><i className="bx bxs-truck"></i></div>
                                <h4 className="title"><a href="">Envíamos a domicilio</a></h4>
                                <p className="description">Llevamos tus materiales hasta la ubicación que nos indiques.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon-box icon-box-orange">
                                <div className="icon"><i className="bx bx-dollar"></i></div>
                                <h4 className="title"><a href="">Multiples formas de pago</a></h4>
                                <p className="description">Contamos con distintas formas de pago para tu mayor comodidad.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Fragment>
    )
}

export default Inicio;