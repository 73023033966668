import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { getDetalles, addCotizacion } from '../../actions/productos';
import { recuperarCotizacion } from '../../actions/cotizar';

const ProductoDetalles = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Inicio',
            ruta: '/'
        },
        {
            activo: false,
            nombre: 'Productos',
            ruta: '/productos'
        }, 
        {
            activo: true,
            nombre: 'Detalle',
            ruta: null
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.productos);
    const cotizacion = useSelector(state => state.cotizar);

    const [cotizacionData, setCotizacionData] = useState({
        producto: null, 
        nombre: null, 
        imagen: null, 
        cantidad: null
    });

    const onChange = (e) => {
        let valor = e.target.value;
        if(valor < 0){
            valor = 0;
        }

        setCotizacionData({
            ...cotizacionData,
            [e.target.name]: valor
        });
    }

    const submitCotizacion = async () => {
        await dispatch(addCotizacion(cotizacionData));

        await setCotizacionData({
            ...cotizacionData, 
            cantidad: null
        });
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        if (localStorage.cotizacion) {
            let cotizacion = JSON.parse(localStorage.getItem('cotizacion'));
            dispatch(recuperarCotizacion(cotizacion));
        }

        dispatch(editarBreadcrumb(rutasBread));
    }, []);

    useEffect(() => {
        if(id != null && id != undefined){
            setCotizacionData({
                ...cotizacionData, 
                producto: id
            });

            dispatch(getDetalles(id));
        }
    }, [id, cotizacion]);

    useEffect(() => {
        if(detalles){
            rutasBread[2].nombre = detalles.nombre;

            let cotizacion_informacion = {
                ...cotizacionData, 
                producto: detalles.id, 
                nombre: detalles.nombre, 
                imagen: detalles.imagenes.length > 0 ? detalles.imagenes[0] : null
            };

            if(cotizacion.productos.length > 0){
                cotizacion_informacion.cantidad = cotizacion.productos.find( producto => producto.producto == detalles.id )?.cantidad;
            }

            setCotizacionData(cotizacion_informacion);

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [detalles, cotizacion]);

    return (
    	<Fragment>            
            <section id="portfolio-details" className="portfolio-details">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <div className="swiper-wrapper align-items-center">
                                    {
                                        detalles?.imagenes.map((imagen, index) => {
                                            return(
                                                <div key={'imagen-' + index} className="swiper-slide">
                                                    <img src={imagen} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="swiper-pagination"></div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="portfolio-info">
                                <h3>{detalles?.nombre}</h3>
                                <ul>
                                    {
                                        detalles?.resistencia ? 
                                            <li><strong>Resistencia</strong>: {detalles?.resistencia}</li>
                                        :
                                            null
                                    }
                                    {
                                        detalles?.anchura ? 
                                            <li><strong>Anchura</strong>: {detalles?.anchura}</li>
                                            :
                                            null
                                    }
                                    {
                                        detalles?.altura ? 
                                            <li><strong>Altura</strong>: {detalles?.altura}</li>
                                            :
                                            null
                                    }
                                    {
                                        detalles?.largo ? 
                                            <li><strong>Largo</strong>: {detalles?.largo}</li>
                                            :
                                            null
                                    }
                                </ul>

                                <div className="php-email-form">
                                    <div className="row">
                                        <div className="col-lg-6 form-group mt-3">
                                            <input type="number" name="cantidad" className="form-control" placeholder="Cantidad" value={cotizacionData.cantidad || ''} min="0" onChange={e => onChange(e)} />
                                        </div>
                                        <div className="col-lg-6 form-group mt-3">
                                            <div className="text-center"><button type="button" onClick={() => submitCotizacion()}>{'Cotizar'}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
	    </Fragment>
    )
}

export default ProductoDetalles;