// Alerts
export const ALERT = {
    AGREGAR: 'SET_ALERT',
    ELIMINAR: 'REMOVE_ALERT'
};

//Breadcrumb
export const BREADCRUMB = {
    LISTAR: 'SET_BREADCRUMB'
};

//Productos
export const PRODUCTS = {
    DETALLES: 'DETAIL_PRODUCT'
};

//Cotizar
export const QUOTATION = {
    AGREGAR: 'ADD_QUOTATION', 
    ELIMINAR: 'DELETE_QUOTATION', 
    RECUPERAR: 'RECOVER_QUOTATION', 
    LIMPIAR: 'CLEAN_QUOTATION', 
    ENVIAR: 'SEND_QUOTATION'
};