import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { sendMensaje } from '../../actions/contacto';

const Contacto = () => {    

    const dispatch = useDispatch();

    const [mensajeData, setMensajeData] = useState({
        nombre: null,
        email: null,
        telefono: null, 
        asunto: null, 
        mensaje: null
    });

    const [envioMensaje, setEnvioMensaje] = useState(false);

    const onChange = (e) => {
        setMensajeData({
            ...mensajeData,
            [e.target.name]: e.target.value
        });
    }

    const submitMensaje = async () => {
        await setEnvioMensaje(true);
        await dispatch(sendMensaje(mensajeData));
        await setEnvioMensaje(false);
        await setMensajeData({
            nombre: null,
            email: null,
            telefono: null, 
            asunto: null, 
            mensaje: null
        });
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="300">
                <div className="container">

                    <div className="row">

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bx bx-map"></i>
                                        <h3>Los Arellano</h3>
                                        <p>
                                            Carretera a los Arellano, Km. 3<br/>
                                            Aguascalientes, Ags.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bx bx-map"></i>
                                        <h3>Tepezalá</h3>
                                        <p>
                                            Carr. Federal 22, Km. 17<br/>
                                            Tepezalá, Ags.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bx bx-envelope"></i>
                                        <h3>Correos</h3>
                                        <p>ventas@imhablock.com<br/>contacto@imhablock.com</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bx bx-phone-call"></i>
                                        <h3>Teléfonos</h3>
                                        <p>449 242 9913<br/>449 971 11 (47 al 50) ext. 104</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="php-email-form">
                                <div className="row">
                                    <div className="col-lg-6 form-group mt-3">
                                        <input type="text" name="nombre" className="form-control" placeholder="Nombre" value={mensajeData.nombre || ''} onChange={e => onChange(e)} />
                                    </div>
                                    <div className="col-lg-6 form-group mt-3">
                                        <input type="email" className="form-control" name="email" placeholder="Correo" value={mensajeData.email || ''} onChange={e => onChange(e)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group mt-3">
                                        <input type="text" name="telefono" className="form-control" placeholder="Teléfono" value={mensajeData.telefono || ''} onChange={e => onChange(e)} />
                                    </div>
                                    <div className="col-lg-6 form-group mt-3">
                                        <input type="text" className="form-control" name="asunto" placeholder="Asunto" value={mensajeData.asunto || ''} onChange={e => onChange(e)} />
                                    </div>
                                </div>

                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="mensaje" rows="5" placeholder="Mensaje" value={mensajeData.mensaje || ''} onChange={e => onChange(e)}></textarea>
                                </div>

                                {
                                    envioMensaje ?
                                        <div className="text-center mt-3"><button type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Envíanos tu mensaje</button></div>
                                    :
                                        <div className="text-center mt-3"><button type="button" onClick={() => submitMensaje()}>Envíanos tu mensaje</button></div>
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </section>
                
            <section className="map mt-2">
                <div className="container-fluid p-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3703.6021819000016!2d-102.2834629890157!3d21.83429636444753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429edbdd1d9bfa3%3A0xa3234a9949faf545!2sIMHASA%20Industrial%20Mexicana%20del%20Hierro%20y%20el%20Acero!5e0!3m2!1ses!2smx!4v1702492816852!5m2!1ses!2smx" frameBorder="0" style={{border: '0'}} allowFullScreen></iframe>
                </div>
            </section>
	    </Fragment>
    )
}

export default Contacto;