import { PRODUCTS, QUOTATION } from './types';

import { setAlert } from './alert';

export const getDetalles = (id) => async dispatch => {
    try {
        dispatch({
            type: PRODUCTS.DETALLES,
            payload: id
        });
    } catch (error) {
        console.log(error);
        dispatch(setAlert('Ocurrió un error al obtener el producto.', 'danger'));
    }
}

export const addCotizacion = (cotizacion) => async dispatch => {
    try {
        if(cotizacion.cantidad > 0){
            dispatch({
                type: QUOTATION.AGREGAR,
                payload: cotizacion
            });

            dispatch(setAlert('Cotización actualizada correctamente.', 'success'));
        }else{
            dispatch(setAlert('La cantidad mínima a cotizar es 1.', 'danger'));
        }        
    } catch (error) {
        console.log(error);
        dispatch(setAlert('Ocurrió un error al agregar el producto.', 'danger'));
    }
}