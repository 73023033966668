import React, { useEffect, Fragment } from 'react';

const Productos = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>            
            <section className="portfolio">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <ul id="portfolio-flters">
                            <li data-filter="*" className="filter-active">Todos</li>
                            <li data-filter=".filter-block">Block</li>
                            <li data-filter=".filter-tabicon">Tabicon</li>
                            <li data-filter=".filter-bovedilla">Bovedilla</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row portfolio-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="300">

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-block">
                            <a href="/productos/detalles/0" title="Block 12 x 20 x 40">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/block/12x20x40_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Block 12 x 20 x 40</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-bovedilla">
                            <a href="/productos/detalles/1" title="Bovedilla">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/bovedilla/bovedilla_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Bovedilla</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-block">
                            <a href="/productos/detalles/2" title="Block 15 x 20 x 40">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/block/15X20X40_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Block 15 x 20 x 40</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-tabicon">
                            <a href="/productos/detalles/3" title="Tabicón">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/tabicon/tabicon_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Tabicon</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-block">
                            <a href="/productos/detalles/4" title="Block 20 x 20 x 40">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/block/20x20x40_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Block 20 x 20 x 40</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-block">
                            <a href="/productos/detalles/5" title="Block cara de piedra 20 x 20 x 40">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/block/20x20x40_cara_piedra_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Block cara de piedra 20 x 20 x 40</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-wrap filter-block">
                            <a href="/productos/detalles/6" title="Mitad 12">
                                <div className="portfolio-item">
                                    <img src="assets/img/productos/block/mitad_12_min.png" className="img-fluid" alt="" />
                                    <div className="portfolio-info">
                                        <h3>Mitad 12</h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </section>
	    </Fragment>
    )
}

export default Productos;