import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
		<header id="header" className="fixed-top d-flex align-items-center header-transparenta">
			<div className="container d-flex justify-content-between align-items-center">

				<div className="logo">
					<a href="/"><img src="/assets/img/logotipo/logo-min.png" alt="" className="img-fluid" /></a>
				</div>

				<nav id="navbar" className="navbar">
					<ul>
						<li><a href="/" className={location.pathname === '/' ? 'active' : ''}>Inicio</a></li>
						<li><a href="/nosotros" className={location.pathname === '/nosotros' ? 'active' : ''}>Nosotros</a></li>
						<li><a href="/productos" className={location.pathname === '/productos' ? 'active' : ''}>Productos</a></li>
						<li><a href="/cotizar" className={location.pathname === '/cotizar' ? 'active' : ''}>Cotizar</a></li>
						<li><a href="/contacto" className={location.pathname === '/contacto' ? 'active' : ''}>Contacto</a></li>
					</ul>
					<i className="bi bi-list mobile-nav-toggle"></i>
				</nav>

			</div>
		</header>
    )
}

export default Header;