import { BREADCRUMB } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case BREADCRUMB.LISTAR:
            return payload;
            
        default:
            return state;
    }
}