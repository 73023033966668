import React from 'react';

const Footer = () => {
    return (
		<footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">

			<div className="footer-top">
				<div className="container">
					<div className="row">

						<div className="col-lg-3 col-md-6 footer-links">
							<h4>Secciones</h4>
							<div className="row mx-0">
								<ul className="col-md-6">
									<li><i className="bx bx-chevron-right"></i> <a href="/">Inicio</a></li>
									<li><i className="bx bx-chevron-right"></i> <a href="/nosotros">Nosotros</a></li>
									<li><i className="bx bx-chevron-right"></i> <a href="/productos">Productos</a></li>
								</ul>
								<ul className="col-md-6">
									<li><i className="bx bx-chevron-right"></i> <a href="/cotizar">Cotizar</a></li>
									<li><i className="bx bx-chevron-right"></i> <a href="/contacto">Contacto</a></li>
								</ul>
							</div>
						</div>

						<div className="col-lg-3 col-md-6 footer-contact">
							<h4>Suc. Los Arellano</h4>
							<p>
								Carretera a los Arellano, Km. 3 <br/>
								C.P. 20298<br/>
								Aguascalientes, Ags. <br/><br/>
								<strong>Teléfono:</strong> 449 242 9913<br/>
								<strong>Correo:</strong> ventas@imhablock.com<br/>
							</p>
						</div>

						<div className="col-lg-3 col-md-6 footer-contact">
							<h4>Suc. Tepezalá</h4>
							<p>
								Carr. Federal 22, Km. 17 <br/>
								C.P. 20604<br/>
								Tepezalá, Ags. <br/><br/>
								<strong>Teléfono:</strong> 449 242 9913<br/>
								<strong>Correo:</strong> ventas@imhablock.com<br/>
							</p>
						</div>

						<div className="col-lg-3 col-md-6 footer-info">
							<h3>Nuestras redes</h3>
							<p>
								Comunicate en cualquiera de nuestras redes o en los siguientes canales: <br/><br/>

								<strong>Teléfono:</strong> 449 971 11 (47 al 50) ext. 104<br/>
								<strong>Correo:</strong> contacto@imhablock.com<br/>
							</p>
							<div className="social-links mt-3">
								<a href="#" className="twitter"><i className="bx bxl-whatsapp"></i></a>
								<a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
								<a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="container">
				<div className="copyright">
					{ new Date().getFullYear() } <strong><span>IMHABLOCK</span></strong>
				</div>
				<div className="credits">
					Creada por <a href="https://solgeek.com/">Solgeek</a>
				</div>
			</div>
		</footer>
    )
}

export default Footer;