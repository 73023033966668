import axios from 'axios';

import { QUOTATION } from './types';

import { setAlert } from './alert';

export const deleteCotizacion = (producto) => async dispatch => {
    try {
        dispatch({
            type: QUOTATION.ELIMINAR,
            payload: producto
        });

        dispatch(setAlert('Cotización actualizada correctamente.', 'success'));       
    } catch (error) {
        console.log(error);
        dispatch(setAlert('Ocurrió un error al remover el producto.', 'danger'));
    }
}

export const recuperarCotizacion = (cotizacion) => async dispatch => {
    try {
        dispatch({
            type: QUOTATION.RECUPERAR,
            payload: cotizacion
        });
    } catch (error) {
        console.log(error);
        dispatch(setAlert('Ocurrió un error al recuperar tu cotización.', 'danger'));
    }
}

export const sendCotizacion = (cotizacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(cotizacion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cotizar/enviar_cotizacion`, body, config);

        dispatch({
            type: QUOTATION.LIMPIAR
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error?.response?.data?.msg) {
            dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}