import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './views/Inicio';
import Nosotros from './views/Nosotros';
import Productos from './views/Productos';
import ProductoDetalles from './views/ProductoDetalles';
import Cotizar from './views/Cotizar';
import Contacto from './views/Contacto';

import Alert from './components/layout/Alert';
import Header from './components/layout/Header';
import Breadcrumb from './components/layout/Breadcrumb';
import Footer from './components/layout/Footer';
import Extras from './components/layout/Extras';
import NotFoundPage from './components/routing/NotFoundPage';

//Redux
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Header></Header>
					<Alert></Alert>
					<main id="main">
						<Breadcrumb></Breadcrumb>
						<Routes>
							<Route exact path="/" element={<Inicio />} />
							<Route exact path="/nosotros" element={<Nosotros />} />
							<Route exact path="/productos" element={<Productos />} />
							<Route exact path="/productos/detalles/:id" element={<ProductoDetalles />} />
							<Route exact path="/cotizar" element={<Cotizar />} />
							<Route exact path="/contacto" element={<Contacto />} />

							{/* Cuando no encuentra nada*/}
							<Route path="*" element={<NotFoundPage />}/>
						</Routes>
					</main>
					<Footer></Footer>
					<Extras></Extras>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
