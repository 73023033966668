import { QUOTATION } from '../actions/types'

const initialState = {
    productos: [], 
    nombre: null, 
    email: null, 
    telefono: null, 
    comentarios: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case QUOTATION.AGREGAR:
            let productos_agregar = state.productos;

            let producto_existente_agregar = productos_agregar.find( producto => producto.producto === payload.producto );

            if(!producto_existente_agregar){
                productos_agregar = [
                    ...productos_agregar, payload
                ];
            }else{
                producto_existente_agregar.cantidad = parseInt(payload.cantidad);
                productos_agregar = productos_agregar.map(producto => producto.producto === producto_existente_agregar.producto ? producto_existente_agregar : producto);
            }

            productos_agregar.sort(function (a, b) {
                if (a.producto > b.producto) {
                    return 1;
                }
                if (a.producto < b.producto) {
                    return -1;
                }
                return 0;
            });

            localStorage.setItem('cotizacion', JSON.stringify({
                ...state,
                productos: productos_agregar
            }));

            return {
                ...state,
                productos: productos_agregar
            };

        case QUOTATION.ELIMINAR:
            let productos_eliminar = state.productos.filter(producto => producto.producto !== payload);

            productos_eliminar.sort(function (a, b) {
                if (a.producto > b.producto) {
                    return 1;
                }
                if (a.producto < b.producto) {
                    return -1;
                }
                return 0;
            });

            localStorage.setItem('cotizacion', JSON.stringify({
                ...state,
                productos: productos_eliminar
            }));

            return {
                ...state,
                productos: productos_eliminar
            };

        case QUOTATION.RECUPERAR:
            return {
                ...state,
                ...payload
            };

        case QUOTATION.LIMPIAR:
            localStorage.removeItem('cotizacion');

            return {
                ...state,
                productos: [], 
                nombre: null, 
                email: null, 
                telefono: null, 
                comentarios: null
            };
            
        default:
            return state;
    }
}